<template>
	<layouts-identify :buttonBack="{ path: '/identify' }">
		<div class="pt-7 pb-4">
			<h3 class="ls-tight fw-bolder h3">Ingresá tu email</h3>
		</div>
		<form class="vstack gap-5" @submit.prevent="submit">
			<div>
				<div class="form-floating" :class="{'is-invalid': validation.email.$error}">
					<input type="email" id="email" v-model="email" class="form-control" :class="{'is-invalid': validation.email.$error}" placeholder="Ingresá tu email" v-focus>
					<label for="email">Email</label>
				</div>
				<span class="mt-1 invalid-feedback" v-for="error of validation.email.$errors" :key="error.$uid">{{ error.$message }}</span>
			</div>
			<div class="text-center" v-if="validationExternal.length > 0">
				<div class="invalid-feedback d-block" v-for="(error, index) in validationExternal" v-bind:key="index">{{ error }}</div>
			</div>
			<div>
				<button type="submit" class="btn btn-dark d-flex align-items-center w-100" :disabled="submitting">
					<div class="flex-fill me-n5">Continuar</div>
					<span class="icon icon-sm ps-2">
						<i class="bi bi-chevron-right"></i>
					</span>
				</button>
			</div>
		</form>
	</layouts-identify>
</template>

<script>
	import { reactive, toRefs } from 'vue';
	import router from '@/router';
	import useVuelidate from '@vuelidate/core';
	import { required, email } from '@/helpers/i18n/validators';
	import composableForm from '@/composables/form';
	import UsersRepository from '@/repositories/UsersRepository';

	export default {
		setup() {
			const { submitting, validationExternal, catchError } = composableForm();

			const form = reactive({
				email: ''
			});

			const validation = useVuelidate({
				email: {
					required,
					email
				}
			}, form);

			async function submit() {
				validationExternal.value = [];
				if(!await validation.value.$validate()) return;

				submitting.value = true;
				UsersRepository.identify({
					type: 'email',
					value: form.email
				}).then((response) => {
					var query = { target_type: 'email', target: form.email };

					if(response.data.exists) router.push({ name: 'identify.verification', query: query });
					else router.push({ name: 'identify.signin', query: query });
				}).catch(catchError);
			}

			return { ...toRefs(form), validation, validationExternal, submitting, submit };
		}
	}
</script>